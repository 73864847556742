import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import ('./components/home.vue')
  },
  {
    path: '/:slug',
    name: 'lernzettel',
    component: () => import ('./components/lernzettel.vue')
  },
  {
    path: '/themen/:slug',
    name: 'themen',
    component: () => import ('./components/themen.vue')
  },
  {
    path: '/funktionen/suche',
    name: 'search',
    component: () => import ('./components/search.vue')
  },
  {
    path: '/funktionen/inhaltsverzeichnis',
    name: 'inhaltsverzeichnis',
    component: () => import ('./components/inhaltsverzeichnis.vue')
  },
  {
    path: "/zeitstrahl/gottesbeweise",
    name: "Zeitstrahl der Gottesbeweise",
    component: () => import ('@/views/timelines/gottesbeweise')
  },
  {
    path: "/zeitstrahl/religionskritik",
    name: "Zeitstrahl der Religionskritik",
    component: () => import ('@/views/timelines/religionskritik')
  },
  { path: '/fehler/404', component: () => import ('@/components/notfound.vue') },
  { path: '*', component: () => import ('@/components/notfound.vue') }
]

const router = new Router({mode: 'history', routes, scrollBehavior (to, from, savedPosition) {
  return { x: 0, y: 0 }
}})

export default router
