
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify, {theme: false});

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg'
  }
});
