import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import './../node_modules/bulma/css/bulma.css'
import VueProgressBar from 'vue-progressbar'
import VueMeta from 'vue-meta'

const options = {
  color: '#bffaf3',
  autoFinish: false
}

Vue.use(VueProgressBar, options)

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')